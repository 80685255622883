import React from "react";
import './App.css';
import io from "socket.io-client";
import PromoPicture from "./promo.jpg"


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 1, // 0 = Picking User, 1 = picked user , 2 = Playing, 3 = Promotion
            timeLeft: 15,
            username: "USER",
            commandSent: "Left",
            socket: io("https://clawcrazy.skybounddev.com")
        }

    }

    componentDidMount() {
        this.state.socket.on("update", data => {
            this.setState({
                stage: parseInt(data.stage),
                timeLeft: parseInt(data.timeLeft),
                username: data.username,
                commandSent: data.commandSent
            })

            // Setup Text to Speech
            let msg = new SpeechSynthesisUtterance();

            // Stage 0
            if (parseInt(data.stage) === 0 && parseInt(data.timeLeft) === 20) {
                msg.text = "Picking a User";
                window.speechSynthesis.speak(msg);
            }

            // Stage 1
            if (parseInt(data.stage) === 1) {
                msg.text = "It's " + data.username +"s turn! Enter your first command to start playing!";
                window.speechSynthesis.speak(msg);
            }

            // Stage 2
            if (parseInt(data.stage) === 2 && parseInt(data.timeLeft) === 60) {
                msg.text = data.username + " is playing";
                window.speechSynthesis.speak(msg);
            }
        });
    }

    render() {
        if (this.state.stage === 0) {
            return (
                <div className="main">
                    <h1>PICKING USER in {this.state.timeLeft}s</h1>
                    <h2>Comment "me" to have a chance to play</h2>
                </div>
            );
        }
        if (this.state.stage === 1) {
            return (
                <div className="main">
                    <h1>Picked USER: {this.state.username}</h1>
                    <h2>Type your first command to start your time</h2>
                    <h4>Commands: "right", "left", "forward", "back"</h4>
                </div>
            );
        }
        if (this.state.stage === 2) {
            return (
                <div className="main">
                    <h1>{this.state.username} is playing</h1>
                    <br />
                    <h2>Last Command: {this.state.commandSent}</h2>
                    <h3>You have {this.state.timeLeft}s left!</h3>
                    <br/>
                    <h4>Type "right", "left", "forward", "back", or "drop"</h4>
                </div>
            );
        }
        if (this.state.stage === 3) {
            return <img src={PromoPicture} alt={"Promo"}/>
        }
    }
}

export default App;
